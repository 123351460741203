<template>
  <div class="clearfix">
    <div class="container my-5">
      <div class="row">
        <div class="col-md-12">
          <br />
          <br />
          <h2 class="text-dark">Consulta de certificados:</h2>
          <br />
          <br />
          <transition name="fade" mode="out-in">
            <template v-if="mensajero.on">
              <b-alert  :variant="mensajero.tipo" show>
                <p>{{ mensajero.texto }}</p>
                <br>
                <b-button variant="secondary" @click="reset" >Intentar de nuevo</b-button>
              </b-alert>
            </template>
            <template v-else>
              <b-form>
                <div class="row">
                  <b-form-group
                    label="Dictamen No:"
                    label-for="numero_dictamen"
                    class="col-4"
                  >
                    <b-form-input
                      v-model="form.numero_dictamen"
                      :state="numerodictamenOk"
                      class="form-control"
                      id="numero_dictamen"
                      placeholder="Ingrese le No. de Dictamen"
                    ></b-form-input>
                    <span class="text-danger" v-if="errors.numero_dictamen">{{
                      errors.numero_dictamen[0]
                    }}</span>
                  </b-form-group>
                </div>
                <br />
                <div class="row">
                  <b-form-group
                    label="Nit o Cédula:"
                    label-for="numero_identificacion"
                    class="col-4"
                  >
                    <b-form-input
                      type="password"
                      v-model="form.numero_identificacion"
                      :state="numeroidentificacionOk"
                      class="form-control"
                      id="numero_identificacion"
                      placeholder="Ingrese Cédula o NIT sin dígito de verificación ni puntos."
                    ></b-form-input>
                    <span class="text-danger" v-if="errors.numero_identificacion">{{
                      errors.numero_identificacion[0]
                    }}</span>
                  </b-form-group>
                </div>
                <br />
                <vue-recaptcha
                sitekey="6LebINQaAAAAAFBTKKdSxdwzYNGMjgT8HTcjoYcG"
                @verify="onVerify"
                @expired="onExpired"
                @error="onError"
                >
                </vue-recaptcha>
                <br>
                <b-button v-if="showButton" @click="sendForm" title="Aceptar" variant="secondary">
                  <span class="mr-1">Aceptar</span>
                  <font-awesome-icon icon="arrow-right" class="ms-2"/>
                </b-button>
              </b-form>
            </template>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Connection from '@/api/Connection.js'
import Csrf from '@/api/Csrf'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'Documentos',
  components: {
    VueRecaptcha
  },
  mounted () {
    this.$store.commit('setActualPage', 'Documentos de Consulta')
  },
  data () {
    return {
      form: {
        numero_dictamen: null,
        numero_identificacion: null
      },
      showButton: false,
      mensajero: { on: false, tipo: null, texto: null },
      errors: []
    }
  },

  methods: {
    async sendForm () {
      await Csrf.getCookie()
      try {
        const response = await Connection.post('api/ceritificado/', this.form)
        if (response.data.message) {
          this.mensajero = { on: true, tipo: 'warning', texto: response.data.message }
        } else {
          const link = document.createElement('a')
          link.style.display = 'none'
          const type = { type: response.tipo }

          // base64 to blob
          const byteCharacters = atob(response.data.data_base64)
          const byteNumbers = new Array(byteCharacters.length)
          for (let i = 0; i < byteCharacters.length; i++) { byteNumbers[i] = byteCharacters.charCodeAt(i) }
          const byteArray = new Uint8Array(byteNumbers)
          const blob = new Blob([byteArray], { type })

          const url = window.URL.createObjectURL(blob)
          link.href = url
          link.download = response.data.nombre
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          this.mensajero = { on: true, tipo: 'warning', texto: 'Su consulta tuvo exito. Guarde en un lugar seguro el archivo.' }
        }
      } catch (error) {
        this.mensajero = { on: true, tipo: 'danger', texto: 'El sistema ha tenido un problema interno. Intente cerrar el navegador y vuelva ahacer la consulta.' }
        if (error.response.status === 422) {
          this.errors = error.response.data.errors
        }
      }
    },
    async logout () {
      try {
        const response = await Connection.post('/logout', { id: localStorage.getItem('auth') })
        sessionStorage.setItem('Salida', response.data.message)
        this.mensajero = false
      } catch (error) {
        console.log(error)
      }
    },
    reset () {
      this.showButton = false
      this.mensajero = { on: false, tipo: null, texto: null }
      this.form = { numero_dictamen: null, numero_identificacion: null }
    },
    onVerify: function (response) {
      this.showButton = true
      console.log('Verify: ' + response)
    },
    onExpired: function () {
      this.showButton = false
      console.log('Expired')
    },
    onError: function (error) {
      this.showButton = false
      console.log(error)
    }
  },

  computed: {
    tiposolicitanteOk () {
      return this.errors.tipo_solicitante ? true : null
    },
    numerodictamenOk () {
      return this.errors.numero_dictamen ? true : null
    },
    numeroidentificacionOk () {
      return this.errors.numero_identificacion ? true : null
    }
  }
}
</script>

<style>
</style>
